<template>
  <div class="card" style="margin-top:0;">
    <div class="card-header card-header-icon card-header-rose">
      <div class="card-icon">
          <i class="material-icons">notes</i>
      </div>
      <h3 class="card-title">REPORTE DE COSTOS</h3>
    </div>
    <div class="card-body">
      <v-row>
        <v-col cols="8">
          <v-data-table
            dense
            class="mx-2 my-2 elevation-3 text-center"
            no-data-text="No hay datos de presupuestos creados"
            :headers="presupuestosHeaders"
            :items="presupuestosBody"
            :header-props="{ sortIcon: 'arrow_upward' }"
            :hide-default-footer="true"
            fixed-header
          >
            <template v-slot:[`item.actions`]="{item}">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-icon
                    medium
                    class="mr-2"
                    @click="loadCostos(item.id)"
                    v-on="on"
                  >
                    visibility
                  </v-icon>
                </template>
                <span>Cargar presupuesto</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <v-progress-linear v-if="loading"
            indeterminate
            color="blue darken-2"
          ></v-progress-linear>
        </v-col>
        <v-col cols="4" class="text-right">
          <v-row>
            <v-col>
              <v-btn class="ml-2" color="green darken-1" dark @click="download"><i class="material-icons">cached</i> Excel</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      
      <v-data-table
        dense
        class="mx-2 my-2 elevation-3 text-center"
        no-data-text="No hay datos de costo"
        :headers="resultadosListHeaders"
        :items="resultadosListBody"
        :header-props="{ sortIcon: 'arrow_upward' }"
        :hide-default-footer="true"
        fixed-header
      >
      </v-data-table>

      <v-simple-table
        dense
        class="mx-2 my-2 elevation-3 text-center"
        no-data-text="No hay datos de costo"
        :search="search"
        :header-props="{ sortIcon: 'arrow_upward' }"
        fixed-header
        :height="table_height"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center blue-grey darken-4 white-text">Centro Costo</th>
              <th class="text-center blue-grey darken-4 white-text">Cuentas</th>
              <th class="text-center blue-grey darken-4 white-text" v-for="header in costosListHeaders" v-bind:key="header.number">
                {{ header.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="costo in costosListBody" :key="costo.numero">
              <td class="text-left">{{ costo.criterio }}</td>
              <td class="text-left">{{ costo.cuenta.nombre }}</td>
              <td v-for="(header, w) in costosListHeaders" v-bind:key="header.number">
                {{ costo.weeks[w].valor }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-data-table
        dense
        class="mx-2 my-2 elevation-3 text-center"
        no-data-text="No hay datos de costo"
        :headers="resumenListHeaders"
        :items="resumenListBody"
        :header-props="{ sortIcon: 'arrow_upward' }"
        :hide-default-footer="true"
        fixed-header
      >
      </v-data-table>
    </div>
    <v-snackbar
      v-model="snackbar"
      :timeout="5000"
      :color="color"
    >
      {{ validation_message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<style scoped>
  .table-wellboat{
    font-size: 16pt;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
  }
  .card{
    margin-top: 0;
  }
  .card .card-header-rose .card-icon{
    background: #263238;
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(32, 30, 31, 0.822);
  }
  .txt-number{
    width:100px;
    text-align:right !important;
  }
</style>
<script>
  import {mapState, mapMutations} from 'vuex'

  export default {
    data: () => ({
      loading: false,
      opcionReporte: 1,
      color: 'green',
      snackbar: false,
      validation_message: '',
      search: null,
      isLoading: false,
      empresas: [],
      inicio: '',
      termino: '',
      menu1: false,
      menu2: false,
      url_excel: '',
      url_pdf: '',
      table_height: 600,
      cierre: 0,
      cierres: [],
      costo: {
        year: 2021,
        wellboat: {},
        year_presupuesto: 2021,
      },
      selected: [],
      semanas: [],
      wellboats: [],
      presupuestos: [],
      presupuestosHeaders: [
        {
          text: 'Nombre',
          align: 'left',
          sortable: true,
          value: 'nombre',
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Fecha modificacion',
          align: 'center',
          sortable: true,
          value: 'fecha',
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Acciones',
          align: 'left',
          sortable: true,
          value: 'actions',
          width: "120px",
          class: "blue-grey darken-4 white--text"
        },
      ],
      presupuestosBody: [],
      costosListHeaders: [],
      costosListBody: [],
      resumenListHeaders: [
        {
          text: 'Item',
          align: 'center',
          sortable: true,
          value: 'tipo_cc',
          width: "150px",
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Enero',
          align: 'center',
          sortable: true,
          value: 'm1',
          width: "100px",
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Febrero',
          align: 'center',
          sortable: true,
          value: 'm2',
          width: "100px",
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Marzo',
          align: 'center',
          sortable: true,
          value: 'm3',
          width: "100px",
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Abril',
          align: 'center',
          sortable: true,
          value: 'm4',
          width: "100px",
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Mayo',
          align: 'center',
          sortable: true,
          value: 'm5',
          width: "100px",
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Junio',
          align: 'center',
          sortable: true,
          value: 'm6',
          width: "100px",
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Julio',
          align: 'center',
          sortable: true,
          value: 'm7',
          width: "100px",
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Agosto',
          align: 'center',
          sortable: true,
          value: 'm8',
          width: "100px",
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Septiembre',
          align: 'center',
          sortable: true,
          value: 'm9',
          width: "100px",
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Octubre',
          align: 'center',
          sortable: true,
          value: 'm10',
          width: "100px",
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Noviembre',
          align: 'center',
          sortable: true,
          value: 'm11',
          width: "100px",
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Diciembre',
          align: 'center',
          sortable: true,
          value: 'm12',
          width: "100px",
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Total',
          align: 'center',
          sortable: true,
          value: 'total',
          width: "100px",
          class: "blue-grey darken-4 white--text"
        },
      ],
      resumenListBody: [],
      resultadosListHeaders: [
        {
          text: 'Item',
          align: 'left',
          sortable: true,
          value: 'item',
          width: "150px",
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Enero',
          align: 'center',
          sortable: true,
          value: 'm1',
          width: "100px",
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Febrero',
          align: 'center',
          sortable: true,
          value: 'm2',
          width: "100px",
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Marzo',
          align: 'center',
          sortable: true,
          value: 'm3',
          width: "100px",
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Abril',
          align: 'center',
          sortable: true,
          value: 'm4',
          width: "100px",
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Mayo',
          align: 'center',
          sortable: true,
          value: 'm5',
          width: "100px",
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Junio',
          align: 'center',
          sortable: true,
          value: 'm6',
          width: "100px",
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Julio',
          align: 'center',
          sortable: true,
          value: 'm7',
          width: "100px",
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Agosto',
          align: 'center',
          sortable: true,
          value: 'm8',
          width: "100px",
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Septiembre',
          align: 'center',
          sortable: true,
          value: 'm9',
          width: "100px",
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Octubre',
          align: 'center',
          sortable: true,
          value: 'm10',
          width: "100px",
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Noviembre',
          align: 'center',
          sortable: true,
          value: 'm11',
          width: "100px",
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Diciembre',
          align: 'center',
          sortable: true,
          value: 'm12',
          width: "100px",
          class: "blue-grey darken-4 white--text"
        },
        {
          text: 'Total',
          align: 'center',
          sortable: true,
          value: 'total',
          width: "100px",
          class: "blue-grey darken-4 white--text"
        },
      ],
      resultadosListBody: [],
    }),
    mounted() {
      this.loadPresupuestos();
      this.loadWellboats();
      if(window.innerHeight < 768){
        this.table_height = 450;
      }
    },
    methods:{
      showWeeks(){
        let months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
        let week = 0;
        let month_prev = this.paras.paras[0].month;
        for(let w = 1; w <= this.paras.last; w++){
          let para = this.paras.paras[w - 1];
          let month = para.month;
          if(month != month_prev){
            week = 1;
          }else{
            week++;
          }
          let tw = months[month - 1] + ' ' + week;
          let s = {
            wellboats_id: this.costo.wellboat.id,
            week: w,
            month: month, 
            year: this.costo.year,
            habilitado: para.activo,
            tipo_para: para.tipo_para
          };
          this.semanas.push(s);
          let h = {
            text: tw,
            align: 'center',
            sortable: true,
            value: 'week' + w,
            width: "100px",
            class: "blue-grey darken-4 white--text"
          };
          this.costosListHeaders.push(h);
          month_prev = month;
        }
      },
      async getParas(){
        let url = `${this.base_url}paras_wellboats/bywellboat/${this.costo.wellboat.id}/${this.costo.year}`;
        await this.axios.get(url, this.headers).then((response) => {
          this.paras = response.data;
          this.showWeeks();
        }).catch((error)=>{
          console.log(error);
        });
      },
      async loadWellboats(){
        let url = `${this.base_url}wellboats/lista/select`;
        await this.axios.get(url, this.headers).then((response) => {
          this.wellboats = response.data;
          this.costo.wellboat = this.wellboats[0];
          this.getParas();
        }).catch((error)=>{
          console.log(error);
        });
      },
      async loadPresupuestos(){
        let url = `${this.base_url}costos`;
        await this.axios.get(url, this.headers).then((response) => {
          this.presupuestosBody = response.data;
        }).catch((error)=>{
          console.log(error);
        });
      },
      async loadCostos(id){
        let url = `${this.base_url}costos/por_presupuesto/${id}`;
        this.loading = true;
        await this.axios.get(url, this.headers).then((response) => {
          this.costosListBody = response.data.semanal;
          this.resumenListBody = response.data.resumen;
          this.resultadosListBody = response.data.resultados;
          this.url_excel = this.storage_url.replace('storage/app/public/', 'public/reportes/') + response.data.url_excel;
          this.showSnackBar(false);
          this.loading = false;
        }).catch((error)=>{
          console.log(error);
          this.loading = false;
        });
      },
      async grabar(costo){
        let data = {
          wellboats_id: costo.wellboats_id,
          year: costo.year,
          month: costo.month,
          week: costo.week,
          cuentas_id: costo.cuentas_id,
          valor: costo.valor
        };
        let url = `${this.base_url}costos`;
        await this.axios.post(url, data, this.headers).then(resp => {
          console.log(resp);
        });
      },
      download(){
        window.open(this.url_excel);
      },
      downloadPDF(){
        window.open(this.url_pdf);
      },
      showSnackBar(error){
        if(error){
          this.color = 'red';
        }else{
          this.color = 'green';
          this.validation_message = "Ejecutado exitosamente!";
        }
        this.snackbar = true;
      },
      ...mapMutations(['actualizarBaseUrl', 'mostrarLoading', 'ocultarLoading']),
      formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${day}-${month}-${year}`
      },
      setCierre(){
        this.inicio = this.formatIso(this.cierre.inicio);
        this.termino = this.formatIso(this.cierre.fin);
        if(this.opcionReporte == 1) this.cierre = {};
      },
      formatIso(fecha){
        let arrF = fecha.split('-');
        return `${arrF[2]}-${arrF[1]}-${arrF[0]}`;
      },
      formatNumber(numero, decimales = 2){ //, checknegative = false){
        //if(checknegative && numero < 0) return 'Número Inválido';
        let arrNum = numero.toString().split('.');
        if(arrNum[1] == undefined) arrNum[1] = "";
        let num = arrNum[0];
        if(!isNaN(num)){
          num = num.split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
          num = num.split('').reverse().join('').replace(/^[.]/,'');
          if(decimales > 0) num += ',';
          return num + arrNum[1].padEnd(decimales, '0');
        }else{
          return '0';
        }
      },
    },
    computed:{
      ...mapState(['storage_url', 'loading', 'base_url', 'headers', 'user']),
      inicio_formated () {
        return this.formatDate(this.inicio)
      },
      termino_formated () {
        return this.formatDate(this.termino)
      },
    },
    components:{
    }
  }
</script>